


























































































import { ViewAllResponsesClicked } from '@/responses';
import {
  EscalationsSortCol,
  SortDir,
  StatusIndicatorIcon,
  InlineLoader,
} from '@/shared';
import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import {
  EscalationsTableSorted,
  EscalationsPageUpdated,
  EscalationResponsesRequested,
  EscalationsRowExpanded,
} from './+state';
import Filters from './Filters.vue';

export default defineComponent({
  components: {
    Filters,
    StatusIndicatorIcon,
    InlineLoader,
  },
  setup() {
    const sortCol = sink.select('escalations.sort-col');
    const sortDir = sink.select('escalations.sort-dir');

    const updateSort = (sortInfo: { col: EscalationsSortCol; dir: SortDir }) =>
      sink.broadcast(EscalationsTableSorted(sortInfo));

    const onChangeSortColumn = (col: EscalationsSortCol) => {
      updateSort({ col, dir: sortDir.value });
    };

    const onChangeSortDirection = (isDesc: boolean) => {
      updateSort({ col: sortCol.value, dir: isDesc ? 'desc' : 'asc' });
    };

    const onItemExpanded = ({ item, value: isExpanded }) => {
      sink.broadcast(EscalationsRowExpanded({ item, isExpanded }));

      if (isExpanded) {
        sink.broadcast(EscalationResponsesRequested({ id: item.checkupId }));
      }
    };

    return {
      onItemExpanded,
      viewAllResponses: row => {
        const payload = {
          patientId: row.clinicId,
          question: row.question,
        };
        sink.broadcast(ViewAllResponsesClicked(payload));
      },
      onChangeSortColumn,
      onChangeSortDirection,
      updatePage: page => {
        sink.broadcast(EscalationsPageUpdated({ page }));
      },
      escalations: sink.select('escalations.table'),
      sortCol,
      sortDir,
      count: sink.select('escalations.count'),
      pageNumber: sink.select('escalations.page-number'),
      expandedRows: sink.select('escalations.table-expanded-rows'),
      loading: sink.select('escalations.loading'),
      tableMessage: sink.select('escalations.tableMessage'),
      headers: [
        { value: 'chatDate', text: 'Chat Date', sortable: true },
        {
          value: 'patientName',
          text: 'Patient',
          sortable: true,
        },
        { value: 'programModule', text: 'Program Module', sortable: false },
        { value: 'providerName', text: 'Provider', sortable: false },
        { value: 'chatStatus', text: 'Chat Status', sortable: true },
        { value: 'data-table-expand', text: '', sortable: false },
      ],
      subTableHeaders: [
        { value: 'question', text: 'Question', sortable: false },
        { value: 'response', text: 'Response', sortable: false },
        { value: 'responseStatus', text: 'Response Status', sortable: false },
        { value: 'linkIcon', text: 'Response History', sortable: false },
      ],
    };
  },
});
